import React from "react";
import {
  withStyles,
  Badge as BadgeBase,
  Typography as TypographyBase,
  Button as ButtonBase,
} from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/styles";
import classnames from "classnames";

// styles
var useStyles = makeStyles(theme => ({
  badge: {
    fontWeight: 600,
    height: 16,
    minWidth: 16,
  },
   cls1:{
       fill:"#1a1a1a",
    },
    cls2:{
       fill:"#10a64a",
       fillRule:"evenodd",
    },
}));

function Badge({ children, colorBrightness, color, ...props }) {
  var classes = useStyles();
  var theme = useTheme();
  var Styled = createStyled({
    badge: {
      backgroundColor: getColor(color, theme, colorBrightness),
    },
  });

  return (
    <Styled>
      {styledProps => (
        <BadgeBase
          classes={{
            badge: classnames(classes.badge, styledProps.classes.badge),
          }}
          {...props}
        >
          {children}
        </BadgeBase>
      )}
    </Styled>
  );
}

function Typography({
  children,
  weight,
  size,
  colorBrightness,
  color,
  ...props
}) {
  var theme = useTheme();

  return (
    <TypographyBase
      style={{
        color: getColor(color, theme, colorBrightness),
        fontWeight: getFontWeight(weight),
        fontSize: getFontSize(size, props.variant, theme),
      }}
      {...props}
    >
      {children}
    </TypographyBase>
  );
}

function Button({ children, color, className, ...props }) {
  var theme = useTheme();

  var Styled = createStyled({
    root: {
      color: getColor(color, theme),
    },
    contained: {
      backgroundColor: getColor(color, theme),
      boxShadow: theme.customShadows.widget,
      color: `${color ? "white" : theme.palette.text.primary} !important`,
      "&:hover": {
        backgroundColor: getColor(color, theme, "light"),
        boxShadow: theme.customShadows.widgetWide,
      },
      "&:active": {
        boxShadow: theme.customShadows.widgetWide,
      },
    },
    outlined: {
      color: getColor(color, theme),
      borderColor: getColor(color, theme),
    },
    select: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  });

  return (
    <Styled>
      {({ classes }) => (
        <ButtonBase
          classes={{
            contained: classes.contained,
            root: classes.root,
            outlined: classes.outlined,
          }}
          {...props}
          className={classnames(
            {
              [classes.select]: props.select,
            },
            className,
          )}
        >
          {children}
        </ButtonBase>
      )}
    </Styled>
  );
}

function Approved (){

  const classes = useStyles();
  
     return (
         <>
             <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120.66 122.88">
  
             <path className={classes.cls1} d="M60,0A59.69,59.69,0,0,1,83,4.56h0a60.39,60.39,0,0,1,32.51,32.51l.09.24a60,60,0,0,1,1.57,41.14,28.91,28.91,0,0,0-7.54-5.3,51.64,51.64,0,0,0-2.1-32.52l-.09-.21A51.48,51.48,0,0,0,96.28,23.77v0A51.44,51.44,0,0,0,40.62,12.53l-.21.09A51.46,51.46,0,0,0,23.77,23.75l0,0A51.44,51.44,0,0,0,12.53,79.43l.09.2a51,51,0,0,0,6.29,11.05c7-3.73,18.83-3.39,25.59-8.11a18.33,18.33,0,0,0,1.36-2.65c.69-1.57,1.31-3.28,1.71-4.44A54.46,54.46,0,0,1,43.1,69.1c-2.17-3.45-7-9.93-7.1-13.78A5.18,5.18,0,0,1,36.44,53,4.45,4.45,0,0,1,38,51.15a4.92,4.92,0,0,1,1.1-.55,115.73,115.73,0,0,1-.22-13,17.79,17.79,0,0,1,.56-2.93A17.37,17.37,0,0,1,47.09,25a24.28,24.28,0,0,1,6.41-2.85c1.44-.4-1.23-5,.26-5.15C61,16.22,72.58,22.79,77.6,28.22A17.66,17.66,0,0,1,82,39.32l-.28,11.75h0a3.26,3.26,0,0,1,2.38,2.47c1.09,4.28-4.63,11.79-6.48,14.83a44.62,44.62,0,0,1-6.32,8.72l.67,1c.73,1.07,1.47,2.14,2.31,3.15a28.88,28.88,0,0,0,.77,36.91,60,60,0,0,1-38-2.64h0a60.08,60.08,0,0,1-19.47-13v0A59.8,59.8,0,0,1,4.56,83l-.09-.23a60,60,0,0,1,.09-45.67h0a60,60,0,0,1,13-19.47h0a60,60,0,0,1,19.47-13l.23-.09A59.8,59.8,0,0,1,60,0Z"/>
             <path className={classes.cls2} d="M97,75.55A23.67,23.67,0,1,1,73.33,99.21,23.66,23.66,0,0,1,97,75.55ZM90.23,95.79l3.58,3.35,9.36-9.5c.83-.83,1.34-1.5,2.35-.46l3.29,3.37c1.08,1.06,1,1.69,0,2.68L95.69,108.14c-2.15,2.11-1.78,2.24-4,.08l-7-7a1,1,0,0,1,.1-1.48l3.81-4c.58-.61,1-.55,1.63,0Z"/>
             </svg>
         </>
     )
  }

export { Badge, Typography, Button, Approved };

// ########################################################################

function getColor(color, theme, brigtness = "main") {
  if (color && theme.palette[color] && theme.palette[color][brigtness]) {
    return theme.palette[color][brigtness];
  }
}

function getFontWeight(style) {
  switch (style) {
    case "light":
      return 300;
    case "medium":
      return 500;
    case "bold":
      return 600;
    default:
      return 400;
  }
}

function getFontSize(size, variant = "", theme) {
  var multiplier;

  switch (size) {
    case "sm":
      multiplier = 0.8;
      break;
    case "md":
      multiplier = 1.5;
      break;
    case "xl":
      multiplier = 2;
      break;
    case "xxl":
      multiplier = 3;
      break;
    default:
      multiplier = 1;
      break;
  }

  var defaultSize =
    variant && theme.typography[variant]
      ? theme.typography[variant].fontSize
      : theme.typography.fontSize + "px";

  return `calc(${defaultSize} * ${multiplier})`;
}

function createStyled(styles, options) {
  var Styled = function(props) {
    const { children, ...other } = props;
    return children(other);
  };

  return withStyles(styles, options)(Styled);
}
