import React, { useState, lazy, Suspense }  from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// components



// pages
import Error from "../pages/error";
import Login from "../pages/login";
import Forgot from "../pages/forgot password/Forgot";
import Loading from "./Layout/Loading"

// context
import { useUserState } from "../context/UserContext";
const Layout = lazy(() => import('./Layout'))

export default function App() {



  // global
  var { isAuthenticated } = useUserState();



  return (
    <>
    <BrowserRouter >
    <Suspense fallback={<Loading/>}>
      <Switch>
        <Route  path="/"  exact render={() => <Redirect to="/app/dashboard" />} />
        <Route
          
          path="/app"
          exact
          render={() => <Redirect to="/app/dashboard" />}
        />
        <PrivateRoute path="/app" component={Layout} />
        <PublicRoute  path="/forgot-password" exact component={Forgot}/>
        <PublicRoute  path="/login" exact component={Login} />
        <Route component={Error} />
      </Switch>
     </Suspense>
    </BrowserRouter>
    </>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    const tokenid = localStorage.getItem('id_token')
    const[token, setToken] = useState(tokenid);

    if(!token){
      return  <Redirect
              to="/login" component={Login} />
    }

    // console.log(token, "private router")


    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
   

    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
