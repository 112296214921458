import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  card: {
    marginTop:theme.spacing(4),
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    minWidth:"20%",
    marginRight:theme.spacing(3),
    marginLeft:theme.spacing(2),
    position:"relative",
    height:"125px",
    borderRadius:"10px",
  },
  tabstoolbar:{
    display:"flex",
    marginBottom: "0px",
    marginTop:theme.spacing(4)
 },
 taskbar:{
  width: "100%",
  height: "70px",

  display: "flex",
  alignItems: "center",


  borderRadius: "10px",
  justifyContent:"space-between",
  paddingRight:"15px",
  
},
imgpending:{
  width: "100px",
  height: "60px",
  position: "absolute",
  top:" 50px",
  filter: "brightness(0) invert(1)",
  /* float: right; */
  right: "10px",
}

}))