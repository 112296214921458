import React, { useState,useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import Instance from "../../utils/api-axios";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import totalreq from './description-icon.svg';
import totalpending from './schedule-icon.svg';
import approved from './approved-profile-icon (1).svg';
import unapproved from './unapproved-profile-icon.svg';
import allcompany from './society-icon.svg';
import employs from './employees-icon.svg';
import user from './user-note-icon (1).svg'

// styles
import useStyles from "./styles";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// componentsmock
import mock from "./mock";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography  } from "../../components/Wrappers";
import member from "./employees-icon.svg";
import { Approved,Totalreq, Reject,Totalcompanies, TotalMember, User, Pending} from "../../components/atoms/Svgicons";
import { ContactSupportTwoTone, TextRotateVerticalTwoTone } from "@material-ui/icons";
import Lottie from "react-lottie";
import animationData from "./lf20_rlzitsb5.json";


export default function Dashboard(props) {
  var classes = useStyles();
  var theme = useTheme();
  var token=localStorage.getItem('id_token')
  const [totalReq,setTotalReq]=useState([]);
  const [pendingReq,setPendingReq]=useState([])
  const [tableData,setTableData]=useState([]);
  const [loading, setLoading] = useState(true);

  const role = localStorage.getItem('role');
  console.log(role, "role this is for company")

   const  Allcolumns=[
    {
      name:"_id",
      label:"New Req ID",
      options:{
        display:false,
        filter:false,
      }
    },
  {
      name:"username",
      label:"User name",
      options:{
        filter:false,
      }
    },
    {
      name:"service_id",
      label:"Service Name",
      options:{
        filter:false,
      }
    },
    {
      name:"ServiceCity",
      label:"City"
    },
    {
      name:"ServiceDistrict",
      label:"District"
  
    },
    {
      name:"Service_date_of_apply",
      label:"Date of Apply",
      // dateSetting: { locale: "ko-KR"},
      options:{
        customBodyRender:function(value) 
       { 
        return new Date(value).toLocaleDateString(); 
      },
      
  
    }
  },

  {
    name:"company_id",
    label:"Company"
  },
    {
      name:"status",
      label:"User Status",
      options:{
      customBodyRender:(value)=>{
        return(
          <>
          {value === "Rejected" ? 
          <div style={{
    background:"linear-gradient(to left,#F7B42C,#FC575E)",
    borderRadius: '25px',
    padding: "4px 0px 4px 0px",
    border: "1px 50% solid grey",
    color: "white",
    textAlign: "center",
            
            
            }}>{value}</div> 
            : (value === "Approved"  ?  <div style={{
    background:"-webkit-gradient(linear, right top, left top, from(#4eda89), to(#1a9f53))",
    borderRadius: '25px',
    padding: "4px 0px 4px 0px",
    border: "1px 50% solid grey",
    color: "white",
    textAlign: "center",
            
            
            }}>{value}</div>  : 
             <div style={{
  background:"linear-gradient(to left, #f4d02b, #e1940e)",
    borderRadius: '25px',
    padding: "4px 0px 4px 0px",
    border: "1px 50% solid grey",
    color: "white",
    textAlign: "center",
            
            
            }}>{value}</div> )} 
          
          </>
     )
      }
    }
  }
  ,
 


  {
    name:"company_status",
    label:"Company Status",
    options:{
    customBodyRender:(value)=>{
      return(
        <>
        {value === "Rejected" ? 
        <div style={{
  background:"linear-gradient(to left,#F7B42C,#FC575E)",
  borderRadius: '25px',
  padding: "4px 0px 4px 0px",
  border: "1px 50% solid grey",
  color: "white",
  textAlign: "center",
          
          
          }}>{value}</div> 
          : (value === "Approved"  ?  <div style={{
  background:"-webkit-gradient(linear, right top, left top, from(#4eda89), to(#1a9f53))",
  borderRadius: '25px',
  padding: "4px 0px 4px 0px",
  border: "1px 50% solid grey",
  color: "white",
  textAlign: "center",
          
          
          }}>{value}</div>  : 
           <div style={{
background:"linear-gradient(to left, #f4d02b, #e1940e)",
  borderRadius: '25px',
  padding: "4px 0px 4px 0px",
  border: "1px 50% solid grey",
  color: "white",
  textAlign: "center",
          
          
          }}>{value}</div> )} 
        
        </>
   )
    }
  }
}
    
   
  ]
  

useEffect(() => {

  async function getPendingRequest(){
    try{
      const result = await Instance.get('/admin-subamdin/get-all/pending/service-request',{
        headers: {
        Authorization: 'Bearer ' + token
      }})

      if(result.status === 200){
              setPendingReq(result?.data?.reverse())
         }
    }catch(err)
    {
      console.log(err)
    }
  }
  getPendingRequest()
    async function getTotalReq(){
      try{
        const result = await Instance.get('/get/count-of/all/status/request',{
          headers: {
          Authorization: 'Bearer ' + token
        }})

        if(result.status === 200){
                console.log(result?.data?.counts, "this is request success")
                setTotalReq(result?.data?.counts)
                setLoading(false);

                // setTableData(result.data.reverse())
          }
      }catch(err)
      {
        console.log(err)
      } 
    }
    getTotalReq()
  },[])


  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };


  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiChip: {
          root: {
            display: "none"
          }
        },
        MuiTableCell:{
          head: {
            background: "linear-gradient(to left, rgb(51 165 222), rgb(51, 165, 222))",
            fontStyle:"bold"
        },
       root: {  
            padding: '8px 8px',
            paddingLeft:"20px",
            paddingRight:"20px",
        },
      },
        MuiTableRow:{
          head:{
            WebkitTextFillColor:"white",
          }
           
        },
      
        MuiToolbar: {
          root: {
            background: "#33A5DE",
            color:"white",
            // WebkitTextFillColor:"white",
          },
        },
       
      }
    });
    const options={
      filterType: "checkbox",
      filter:false,
      search:false,
      download:false,
      viewColumns:false,
      print:false,
      selectableRows:false,
  
    }
  return (
    <>
    <div className={classes.taskbar}>
      {role === "5" ?   <PageTitle title="Admin Dashboard"  />  : role === "2" ? <PageTitle title="Team Dashboard"  /> : <PageTitle title="Company Dashboard"  />}
    
      <PageTitle title="Welcome!"  />
    </div>
     <Grid container spacing={4}>
    <Card className={classes.card}  > 
      <CardContent>

        <Typography variant="h5" style={{fontWeight:"500",}}  component="h2">
        Total Requests
        </Typography>
        <Typography variant="h6" style={{fontSize:"32px",fontWeight:"400"}} >
        {/* {loading ? <Lottie options={defaultOptions} style={{float:"left", marginTop:"-20px"}} height={100} width={100} /> :totalReq.totalrequest} */}
        {loading ? 0 :totalReq.totalrequest}
        </Typography>
          </CardContent>
          {/* <img className={classes.imgpending} src={totalreq}/> */}
          <Totalreq/>
      </Card>

      <Card className={classes.card} >
      <CardContent>
        <Typography variant="h5" style={{fontWeight:"500"}} component="h2">
        Pending Requests
        </Typography>
        <Typography variant="h6"  style={{fontSize:"32px",fontWeight:"400"}} >
          {loading ? 0 :totalReq.totalcountPending}
        </Typography>
      </CardContent>
      <Pending/>
      </Card>

      <Card className={classes.card}  >
      <CardContent>
        <Typography variant="h5" style={{fontWeight:"500"}}  component="h2">
        Approved Requests
        </Typography>
        <Typography variant="h6" style={{fontSize:"32px",fontWeight:"400"}}>
          {loading ? 0 :totalReq.totalcountApproved}
        </Typography>
      </CardContent>
      {/* <img className={classes.imgpending} src={approved}/> */}
      <Approved />
      </Card>

      <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5" style={{fontWeight:"500"}}  component="h2">
        Rejected Requests
        </Typography>
        <Typography variant="h6" style={{fontSize:"32px",fontWeight:"400"}} >
          {loading ? 0 :totalReq.totalcountreject}
        </Typography>
      </CardContent>
      {/* <img className={classes.imgpending} src={unapproved}/> */}
      <Reject/>
      </Card>

    {role !== "7" && 
      <>
      <Card className={classes.card} >
      <CardContent>
        <Typography variant="h5" style={{fontWeight:"500"}} component="h2">
        Total Companies
        </Typography>
        <Typography variant="h6" style={{fontSize:"32px",fontWeight:"400"}} >
          {loading ? 0 :totalReq.totalcompany}
        </Typography>
      </CardContent>
      {/* <img className={classes.imgpending} src={allcompany}/> */}
      <Totalcompanies/>
      </Card>

      <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5" style={{fontWeight:"500"}}  component="h2">
        Total Members
        </Typography>
        <Typography variant="h6" style={{fontSize:"32px",fontWeight:"400"}} >
          {loading ? 0 :totalReq.totalsubadmin}
        </Typography>
      </CardContent>
      {/* <img className={classes.imgpending} src={member}/> */}
      <TotalMember/>
      </Card>

      <Card className={classes.card}  >
      <CardContent>
        <Typography variant="h5" style={{fontWeight:"500"}} component="h2">
        Total Users
        </Typography>
        <Typography variant="h6" style={{fontSize:"32px",fontWeight:"400"}} >
          {loading ? 0 :totalReq.totaluser}
        </Typography>
      </CardContent>
      {/* <img className={classes.imgpending} src={user}/> */}
        <User/>
      </Card>
      </>
      
     }

      {/* <Newrequest /> */}

       </Grid>

       <MuiThemeProvider theme={getMuiTheme}>
     <Grid container spacing={4} className={classes.tabstoolbar}>
        <Grid item xs={12} >
          <MUIDataTable 
            title="Recent Requests"
            data={pendingReq.flat().map((item)=>{
              return{
                _id:item._id,
              username:item.username,
              ServiceDistrict:item.ServiceDistrict[0].dis_name,
              ServiceCity:item.ServiceCity[0].city_name,
              Service_date_of_apply:item.Service_date_of_apply,
              // date_of_installation:item.date_of_installation,
              status:item.status,
              service_id:item.service_id[0]?.service_name,
              company_id:item.company_id[0]?.company_name,
              company_status:item.company_status

            }})}
            columns={Allcolumns}
            options={options}
          />

        </Grid>
       
      </Grid>
      </MuiThemeProvider>
    </>
  );
}

