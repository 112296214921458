import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
 
  headerImg :{
    position: "absolute",
    textAlign: "start",
    marginLeft:"4%",
    marginRight:"5%",
    '@media (max-width: 1400px)' : {
      marginLeft:"6%",
      marginRight:"6%",
    },
    fontSize:"26px",
    fontStyle:"bold",
    top:"73%",
    color:"white",
    
  },
  text1:{
    position: "absolute",
    textAlign: "start",
  
   
    color:"white",
    fontSize:"16px"
  },
  container: {
    height: "100vh",
    width: "100vw",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // position: "absolute",
    top: 0,
    left: 0,
  },
  logotypeContainer: {
    backgroundColor: "white",
    width: "50%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  avatar: {
		marginLeft: theme.spacing(17),
		backgroundColor: theme.palette.secondary.main
	},
  logotypeImage: {
    position:"relative",
    width: '100%',
    height: '100%',
    marginBottom: theme.spacing(0),
    objectFit:'cover',
    borderTopRightRadius: "12%",
    borderBottomRightRadius: "12%"
    
  },
  logosidetypeImage: {
    width: 190,
    marginBottom: theme.spacing(2),
  },
  logotypeText: {
    color: "white",
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down("md")]: {
      fontSize: 48,
    },
  },
  formContainer: {
    width: "50%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor:"White",
   
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  form: {
    width: 320,
  },
  tab: {
    fontWeight: 400,
    fontSize: 12,
  },
  greeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  subGreetingsmall:{
    fontWeight: 400,
    textAlign: "center",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(4),
    fontSize: 16,
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },
  errorMessage: {
    textAlign: "center",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  loginbtn:{
    width: "100%",
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400,
    float: "right",
    marginBottom: theme.spacing(2),
    
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(2),
    },
  },
}));
