import React, {useState,useEffect} from "react";
import { useHistory } from 'react-router-dom';
// import axios from 'axios';
import Instance from '../utils/api-axios';

import { ToastContainer,toast } from 'react-toastify';
import { ComposedChart } from "recharts";
import Dashboard from "../pages/dashboard/Dashboard";
import { Redirect, useLocation } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { LocalActivity, LocalLaundryService, LocalParking} from "@material-ui/icons";
import { ContactPage } from "@mui/icons-material";
// import { Redirect  } from "react-router-dom";
var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();





var data1={}

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
      
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
      <ToastContainer/>
    </UserStateContext.Provider>
   
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);

  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch,loginUser, signOut };

// ###########################################################

async function loginUser(dispatch, login, password, history, setIsLoading, setError) {

  
const notify = (meesage) => toast.error(meesage);

const body = {
  email:login,
  password:password
}


  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {
    // setTimeout(() => {
     
    //   setError(null)
    //   setIsLoading(false)
    //   dispatch({ type: 'LOGIN_SUCCESS' })

    //   history.push('/app/dashboard')
    // }, 2000); 

    console.log(login,password, "Ths is credentials")

    try{
      const result = await Instance.post('/admin-subadmin/login',body)
      
      console.log(result.data)
      // console.log(result.data.data.adsa_status,companydata)

    
      if(result?.data?.data?.adsa_status === 'Active'){
        console.log("admin")
        window.localStorage.setItem('id_token', result.data.token)
        window.localStorage.setItem('role', result.data.data.adsa_role)
        window.localStorage.setItem('name', result.data.data.adsa_name)
        window.localStorage.setItem('_id',result.data.data._id)
        data1=result.data.data._id;
        window.localStorage.setItem('member_status',result.data.data.adsa_status)
        // data1=result.data.data._id;
        window.localStorage.setItem("_id",result.data.data._id)
        setError(null)
        setIsLoading(false)
        dispatch({ type: 'LOGIN_SUCCESS',menuid:data1})
             
        history.push(<Dashboard menuid={data1}/>)
        toast.info('Login success',{
        position: "bottom-left",
        autoClose: 3000,
        closeOnClick: true,
       }) 
  
       }
    
      else if ( result?.data?.company?.company_status === "Active" ) {

        console.log("comapny")
         
        window.localStorage.setItem('id_token', result.data.token )
        window.localStorage.setItem('role',result.data.company.company_role)
        window.localStorage.setItem('name',result.data.company.company_name )
        window.localStorage.setItem('_id', result.data.company._id)
        window.localStorage.setItem('member_status',result.data.company.company_status)
        console.log(result.data.company.company_role)

        // localStorage.setItem('role', result.data.company.company_role)
        // localStorage.setItem('name', result.data.company.company_name)
        // localStorage.setItem('_id',result.data.company._id)
        // data1=result.data.data._id;
        // data1=result.data.data._id;

        data1="123"
      
        setError(null)
        setIsLoading(false)
        dispatch({ type: 'LOGIN_SUCCESS',menuid:data1})
             
        history.push(<Dashboard menuid={data1}/>)
        toast.info('Login success',{
        position: "bottom-left",
        autoClose: 3000,
        closeOnClick: true,
       })
     }

    
     
     else {
       console.log('acti----ve')
        
       history.push('/login');
       toast.error('Please contact your admin to continue');
       setIsLoading(false)
       console.log('inactive')
     }

     console.log("after else")

        // if(result.status === 200){

        //     console.log(result.data.data.adsa_status, "successfully logged in")
             
       
        // }
        // else{
        //   console.log("login")
        // }
       
        
    }
    catch(err){
      if(err.response){
        console.log(err.response.data.errmsg)

      }
       toast.error("Invalid Email & Password")
        setError(true);
        setIsLoading(false);
   
    }

  } 
  
  else {
    toast.error("Invalid Failure")
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }



}

 async function signOut(dispatch, history) {
  var token=localStorage.getItem('id_token')

  const bodyempty = {
    key:"value",
  }
 
  try{
   const result = await Instance.post('/admin-subadmin/logout', bodyempty,{
    headers:{
    Authorization:  `Bearer ${token}`
  }})

    
      if(result.status === 200){
             localStorage.removeItem("id_token");
             localStorage.removeItem("role");
             localStorage.removeItem("name");
             localStorage.removeItem("_id")
             localStorage.removeItem("member_status")
             localStorage.removeItem("member_id")
             localStorage.removeItem("id_company")
             localStorage.removeItem("GR_ID")
             localStorage.removeItem("gr_company_id")
             localStorage.removeItem('new_req_ID')
             localStorage.removeItem('AbouusId')
             localStorage.removeItem('gr_company')
             localStorage.removeItem('user_id')
             localStorage.removeItem('service_id')
             localStorage.removeItem('AboutUsId')
             localStorage.removeItem('PrivacyPolicyId')
       
             dispatch({ type: "SIGN_OUT_SUCCESS" });
             history.push("/login");
      }

  }
  catch(err){

    console.error(err);

  }

}
  

