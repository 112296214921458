import axios from 'axios';

const url =  "https://api.himurja.amazing7studios.com";


 const Instance = axios.create({

    baseURL: url,
    headers: {
        'Content-Type': 'application/json'
    }
});

export default Instance;