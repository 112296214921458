import React, { useState,useEffect} from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter,Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import classnames from "classnames";
import login from './loginimg.png'
import { ToastContainer, toast } from 'react-toastify';


// styles
import useStyles from "./styles";

// logo
import logo from "./logo.svg";
import sidelogin from "./test2.png"; 

import himurja from "../../images/himurjalogo.svg";

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");

  function handleSubmit(){
    return loginUser(
      userDispatch,
      loginValue,
      passwordValue,
      props.history,
      setIsLoading,
      setError,
    )
  
  }
 
    function keyDownHandler(event){
      console.log('User pressed: ', event.key);

      if (event.key === 'Enter' || event.key === "NumpadEnter") {
        console.log("Enter clickd")
        event.preventDefault();
         handleSubmit();
      }
    };



  return (
    <Grid container className={classes.container}>
    
      <div className={classes.logotypeContainer}>
        <img src={login} alt="logo" className={classes.logotypeImage} />
        
        <h1 className={classes.headerImg}>
        Himachal Pradesh Energy Development Agency (HIMURJA)
   
    <Typography className={classes.text1}>
    Himurja has made constructive efforts to promote various renewable energy programmes.
    It has been possible due to the collaborative contribution of the
    Ministry of New and Renewable Energy (MNRE) and the State Government
    </Typography>
        </h1>
      </div>
      
     <div className={classes.formContainer}>
      <Typography variant="h1" className={classes.greeting}>
         <img src={himurja} alt="logo" className={classes.logosidetypeImage} />
       </Typography>

       <Typography variant="h2" className={classes.subGreeting}>
        Welcome
       </Typography>
    
        <div className={classes.form}>
      <Typography variant="h1" className={classes.subGreetingsmall}>
       Please sign in to continue
       </Typography>
          {/* <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
      
            <Tab variant="h5" label="Please sign in to continue" classes={{ root: classes.tab }} />
            <Tab label="TEAM" classes={{ root: classes.tab }} />
            
          </Tabs> */}
    
          {activeTabId === 0 && (
            <React.Fragment>
              {/* <Typography variant="h4" className={classes.greeting}>
                ADMIN/TEAM
              </Typography> */}
              {/* <Button size="large" className={classes.googleButton}>
                <img src={google} alt="google" className={classes.googleIcon} />
                &nbsp;Sign in with Google
              </Button> */}
        
              {/* <div className={classes.formDividerContainer}>
                <div className={classes.formDivider} />
                <Typography className={classes.formDividerWord}></Typography>
                <div className={classes.formDivider} />
              </div> */}
              <ToastContainer />
              {/* <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Something is wrong with your login or password :(
                </Typography>
              </Fade> */}
              <ToastContainer />
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Email Address"
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                onKeyPress={keyDownHandler}
                fullWidth
              />
                <Link style={{textDecoration: 'none'}} to="/forgot-password">
                <Button
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                 
                >
                  Forgot Password?
                </Button>
                </Link>
              <div className={classes.formButtons}>
            
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      loginValue.length === 0 || passwordValue.length === 0
                    }
                    onClick={handleSubmit}
                    // onClick={() =>
                    //   loginUser(
                    //     userDispatch,
                    //     loginValue,
                    //     passwordValue,
                    //     props.history,
                    //     setIsLoading,
                    //     setError,
                    //   )
                    // }
                  variant="contained"
                    color="secondary"
                    size="large"
                    className={classes.loginbtn}
                  >
                    Login
                  </Button>
                )}
              
               
              </div>
            </React.Fragment>
          )}

          
          {activeTabId === 1 && (
            <React.Fragment>
              <Typography variant="h1" className={classes.greeting}>
                Welcome!
              </Typography>
              <Typography variant="h2" className={classes.subGreeting}>
                Create your account
              </Typography>
              {/* <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Something is wrong with your login or password :(
                </Typography>
              </Fade> */}
              <TextField
                id="name"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={nameValue}
                onChange={e => setNameValue(e.target.value)}
                margin="normal"
                placeholder="Full Name"
                type="text"
                fullWidth
              />
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Email Adress"
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
              />
              <div className={classes.creatingButtonContainer}>
              <ToastContainer/>
                {isLoading ? (
                  <CircularProgress size={26} />
                ) : (
                  <Button
                    onClick={() =>
                      loginUser(
                        userDispatch,
                        loginValue,
                        passwordValue,
                        props.history,
                        setIsLoading,
                        setError,
                      )
                    }
                    disabled={
                      loginValue.length === 0 ||
                      passwordValue.length === 0 ||
                      nameValue.length === 0
                    }
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                  >
                    Create your account
                  </Button>
                )}
              </div>
              <div className={classes.formDividerContainer}>
                <div className={classes.formDivider} />
                <Typography className={classes.formDividerWord}></Typography>
                <div className={classes.formDivider} />
              </div>
              {/* <Button
                size="large"
                className={classnames(
                  classes.googleButton,
                  classes.googleButtonCreating,
                )}
              >
                <img src={google} alt="google" className={classes.googleIcon} />
                &nbsp;Sign in with Google
              </Button> */}
            </React.Fragment>
          )}
        </div>
        {/* <Typography color="primary" className={classes.copyright}>
        © 2014-{new Date().getFullYear()} <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://flatlogic.com" rel="noopener noreferrer" target="_blank">Flatlogic</a>, LLC. All rights reserved.
        </Typography> */}
      </div>
    </Grid>
  );
}

export default withRouter(Login);
