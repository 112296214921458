import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";


const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	underline:{
		textDecoration: 'none'

	}
}));

function validateEmail(email) {
	let hasError = true;
	let error = "";
	if (email) {
		return { hasError, error };
	}
}

export default function Forgot() {
	const classes = useStyles();

	const initialState = {
		email: {
			value: "",
			touched: false,
			hasError: true,
			error: ""
		}
	};

	const [email, setEmail] = useState(initialState);

	function handleChange(event) {
		const { value } = event.target;
		const { hasError, error } = validateEmail(value);
		setEmail((prevState) => ({
			...prevState,
			email: {
				value: value,
				touched: false,
				hasError,
				error
			}
		}));
	}

	function handleOnBlur(event) {
		console.log(event.target.value);
	}

	/* TODOs:
	1. check payload 
	2. send post req to the server
	3. display successuflly sent the email 
	4. ""
	*/
	function handleSubmit(event) {
		// event.preventDefault();
		// let apiUrl = `http://api.com`;
		// const payload = {
		// 	email: email.value
		// };
		// console.log("current payload is ", payload);
		// axios.post(apiUrl, payload);
	}

	function handleOnBlue(e) {}
	return (
		<Container component="main" maxWidth="xs">
			<div className={classes.paper}>
				<Avatar className={classes.avatar}></Avatar>
				<Typography component="h1" variant="h5"  >
					Forgot password
				</Typography>
				<form className={classes.form} noValidate>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						autoComplete="email"
						autoFocus
						onChange={handleChange}
						onBlue={handleOnBlue}
					/>

					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="secondary"
						className={classes.submit}
					>
						Send Link
					</Button>
					<Grid container>
						<Link style={{textDecoration: 'none'}} component={RouterLink} to="/" variant="body2">
							{"Back to Sign In"}
						</Link>
					</Grid>
				</form>
			</div>
			{/* <Box mt={8}>
				<Copyright />
			</Box> */}
		</Container>
	);
}
